.contact {
  background-color: #2879fe;
  color: white;
  padding: 15px;
  display: flex;
  justify-content: center;

  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .icons {
      display: flex;

      a {
        display: flex;
        color: white;
        margin-left: 20px;
      }
      a:hover {
        color: black;
      }
    }
  }
}
