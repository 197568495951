.categories {
  display: flex;
  height: 80vh;
  gap: 10px;
  margin: 10px;

  .col {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .col-l {
    flex: 2;
  }

  .row {
    flex: 1;
    display: flex;
    gap: 10px;
    position: relative;
    overflow: hidden;

    button {
      position: absolute;
      min-width: 100px;
      width: fit-content;
      height: 50px;
      padding: 10px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      cursor: pointer;
      border: none;
      background-color: rgba(255, 255, 255, 0.425);
      text-transform: uppercase;
      font-weight: 500;
      transition: all 0.5s;
    }
    button:hover {
      background-color: black;
      color: white;
      border-radius: 25px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
