@mixin devices($breakpoint) {
  //the name of the mixin is devices
  @if $breakpoint == laptop {
    @media only screen and (min-width: 1000px) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media only screen and (max-width: 1000px) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (max-width: 550px) {
      @content;
    }
  }
}
.product {
  padding: 20px 50px;
  display: flex;
  gap: 50px;

  .left {
    flex: 1;
    display: flex;
    gap: 20px;
    .images {
      flex: 1;

      img {
        width: 100%;
        height: 150px;
        object-fit: cover;
        cursor: pointer;
        margin-bottom: 10px;
      }
    }
    .mainImg {
      flex: 5;

      img {
        width: 100%;
        max-height: 800px;
        object-fit: cover;
      }
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .price {
      font-size: 30px;
      color: #2879fe;
      font-weight: 500;
    }

    p {
      font-size: 18px;
      font-weight: 300;
      text-align: justify;
    }

    .quantity {
      display: flex;
      align-items: center;
      gap: 10px;

      button {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: none;
      }
    }

    .add {
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: 0;
      border: 0;
      cursor: pointer;
      background-color: #4299e1;
      border-radius: 4px;
      padding: 8px 16px;
      font-size: 16px;
      font-weight: 700;
      color: white;
      line-height: 26px;
    }

    .links {
      display: flex;
      gap: 20px;

      .item {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #2879fe;
        font-size: 14px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: gray;
      font-size: 14px;
      margin-top: 30px;

      hr {
        width: 200px;
        border: 1px solid rgb(238, 237, 237);
      }
    }

    hr {
      border: 1px solid rgb(238, 237, 237);
    }
  }
}
@include devices(tablet) {
  .product {
    flex-direction: column;
    .left {
      flex-direction: column-reverse;
      .images {
        display: flex;
        flex-direction: row;
        gap: 10px;
        img {
          width: 50%;
        }
      }
    }
  }
}
