@mixin devices($breakpoint) {
  //the name of the mixin is devices
  @if $breakpoint == laptop {
    @media only screen and (min-width: 1000px) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media only screen and (max-width: 1000px) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (max-width: 550px) {
      @content;
    }
  }
}
.footer {
  margin: clamp(1rem, 8vw + 1rem, 20rem) clamp(1rem, 16vw + 1rem, 40rem)
    clamp(1rem, 8vw + 1rem, 2rem) clamp(1rem, 8vw + 1rem, 20rem);
  .top {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-align: justify;
      font-size: 14px;
      h1 {
        font-size: 18px;
        font-weight: 500;
        color: #555;
      }
      span {
        color: grey;
      }
      a {
        color: grey;
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    margin-top: 50px;
    .left {
      display: flex;
      align-items: center;
      .logo {
        color: #2879fe;
        font-weight: bold;
        font-size: 24px;
      }
      .copyright {
        margin-left: 20px;
        font-size: 12px;
        color: grey;
      }
    }
    .right {
      img {
        height: 50px;
      }
    }
  }
}
@include devices(tablet) {
  .footer .bottom .right img {
    height: 30px;
  }
}
