@mixin devices($breakpoint) {
  //the name of the mixin is devices
  @if $breakpoint == laptop {
    @media only screen and (min-width: 1000px) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media only screen and (max-width: 1000px) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (max-width: 550px) {
      @content;
    }
  }
}
.slider {
  height: calc(100vh - 80px);
  position: relative;
  overflow: hidden;

  .container {
    width: 300vw;
    height: 100%;
    display: flex;
    transition: all 1s ease;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .icons {
    width: fit-content;
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100px;
    gap: 10px;
    margin: auto;

    .icon {
      width: 50px;
      height: 50px;
      border: 1px solid #999;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
@include devices(mobile) {
  .slider {
    .container img {
      width: 50%;
      height: auto;
    }
    .icons {
      .icon {
        display: none;
      }
    }
  }
}
