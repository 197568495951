.cart {
  position: absolute;
  right: 20px;
  top: 80px;
  z-index: 999;
  background-color: white;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 7px -5px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 7px -5px rgba(0, 0, 0, 0.5);

  h1 {
    margin-bottom: 30px;
    color: gray;
    font-weight: 400;
    font-size: 24px;
  }

  .item {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
    img {
      width: 80px;
      height: 100px;
      object-fit: cover;
    }

    .details {
      h1 {
        font-size: 18px;
        font-weight: 500;
      }

      p {
        color: gray;
        margin-bottom: 10px;
        font-size: 14px;
      }

      .price {
        color: #2879fe;
      }
    }

    .delete {
      color: red;
      font-size: 30px;
      cursor: pointer;
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 20px;
  }

  button {
    width: 250px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: 0;
    cursor: pointer;
    background-color: #4299e1;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 700;
    color: white;
    line-height: 26px;
  }

  .reset {
    margin-top: 20px;
    width: 125px;
    font-size: 12px;
    background-color: #e14242;
    cursor: pointer;
  }
}
