@mixin devices($breakpoint) {
  //the name of the mixin is devices
  @if $breakpoint == laptop {
    @media only screen and (min-width: 1000px) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media only screen and (max-width: 1000px) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (max-width: 550px) {
      @content;
    }
  }
}
.navbar {
  .wrapper {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    .toggleButton {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      svg {
        font-size: 50px;
      }
      button {
        margin-right: 20px;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
    }
    .links {
      width: 100%;
      padding-left: 5px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      justify-items: flex-start;
      transition: all 0.5s;

      a {
        text-decoration: none;
        margin: 10px;
        font-size: 15px;
        transition: all 0.5s;
        color: black;
      }
      a:hover {
        color: black;
        letter-spacing: 5px;
      }
    }
    .center {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      letter-spacing: 2px;
    }
    .icons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 15px;
      color: #777;
      padding-right: 20px;
      cursor: pointer;
      .carticon {
        position: relative;
        span {
          position: absolute;
          font-size: 12px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #2879fe;
          color: white;
          right: -10px;
          top: -10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
@include devices(laptop) {
  .navbar .wrapper {
    .toggleButton {
      display: none;
    }
  }
  #on {
    .wrapper {
      height: 100px;
      .links {
        flex-direction: row;
      }
    }
  }
}
@include devices(tablet) {
  .navbar.toggleButton {
    display: flex;
  }
  #off {
    .wrapper .links {
      display: none;
    }
  }
  #on {
    .wrapper {
      position: relative;
      justify-content: unset;
      .links {
        width: 150px;
        position: absolute;
        top: 80px;
        flex-direction: column;
        justify-content: flex-start;
        height: fit-content;
        z-index: 1;
        background-color: white;
      }
      a {
        width: 100%;
        text-align: center;
      }
    }
  }
}
@include devices(mobile) {
  .navbar .wrapper {
    .icons .icon {
      display: none;
    }
    .center {
      font-size: 25px;
    }
  }
}
