body {
  padding: 0;
  margin: 0;
}
.app {
  .link {
    color: inherit;
    text-decoration: none;
  }
}
* {
  margin: 0;
  padding: 0;
}
body {
  padding: 0;
  margin: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
$offset: 187;
$duration: 1.4s;

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  div {
    background: #3266cf;
    background: linear-gradient(to right, #3266cf 0%, #33cf2e 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: large;
    margin-bottom: 20px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 0.5rem;
  }
  .spinner {
    animation: rotator $duration linear infinite;
  }

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  .path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash $duration ease-in-out infinite,
      colors ($duration * 4) ease-in-out infinite;
  }

  @keyframes colors {
    0% {
      stroke: #4285f4;
    }
    25% {
      stroke: #de3e35;
    }
    50% {
      stroke: #f7c223;
    }
    75% {
      stroke: #1b9a59;
    }
    100% {
      stroke: #4285f4;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: $offset;
    }
    50% {
      stroke-dashoffset: calc($offset / 4);
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: $offset;
      transform: rotate(450deg);
    }
  }
}
